import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { promiseToFlyTo, getCurrentLocation } from 'lib/map';
import Img from 'gatsby-image'


import Layout from 'components/Layout';
import DonutMap from 'components/DonutMap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes, faListUl, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import CafeSidebar from 'components/CafeSidebar'
import SEO from "components/SEO"
import Container from 'components/Container'
import useSessionStorage from '../hooks/useSessionStorage'
import DistanceCalculator from 'components/DistanceCalculator'

const LOCATION = {
  lat: -41.2945,
  lng: 174.7762,
};
let CENTER = [LOCATION.lat, LOCATION.lng];

const DEFAULT_ZOOM = 15;
const timeToZoom = 0;

const IndexPage = ({data}) => {
  const [isVisible, setIsVisible] = useSessionStorage('sidebar',false);
  const [selectedCafe, setActiveCafe] = useSessionStorage('selectedCafe', false);
  const [currentLocation, setCurrentLocation] = useSessionStorage('location', false);

  const toggleVisible = () => {
    setIsVisible(!isVisible);
  };

  function handleSelectCafe(newValue, lat = 0, lng = 0) {
    setActiveCafe(newValue);
    CENTER = [lat, lng];
  }

  function locationUpdated(newValue, lat = 0, lng = 0) {
    setCurrentLocation(newValue);
  }

  // Init custom map icon
  let donutMarker = null
  let selectedDonutMarker = null
  if (typeof window !== `undefined`) {
    donutMarker = new L.Icon({
      iconUrl:  data.donutMarker.childImageSharp.standard.src,
      iconRetinaUrl: data.donutMarker.childImageSharp.retina.src,
      iconAnchor: [0, 0],
      popupAnchor: [19, -3],
      iconSize: [38, 60]
    });
    selectedDonutMarker = new L.Icon({
      iconUrl:  data.donutMarker.childImageSharp.standard.src,
      iconRetinaUrl: data.donutMarker.childImageSharp.retina.src,
      iconAnchor: [0, 0],
      popupAnchor: [24, -3],
      iconSize: [48, 70]
    });
  }

  return (
    <Layout pageName="home">
      <Container type="donutmap">
      <span className="toggle-sidebar-button" onClick={ toggleVisible }><FontAwesomeIcon className="hamburger" icon={ isVisible ? faMapMarkerAlt: faListUl } size='1x'/></span>
        <div className="map-wrapper">
          <DonutMap center={CENTER} sidebarVisible={isVisible} locationHandler={locationUpdated}>
            { data.allAirtable.edges.map((data, index) => {
              const cafeLocation = [data.node.data.latitude, data.node.data.longitude]
              return (
                <Marker position={ cafeLocation } key={ index } icon={ (selectedCafe===index ? selectedDonutMarker: donutMarker)}>
                  <Popup> 
                    <div className="popup-cafe-container">
                      <figure className="item-image">
                          <Link to={ data.node.data.path }><Img fixed={data.node.data.photos.localFiles[0].childImageSharp.fixed} className="circle-crop"/></Link>
                      </figure>
                      <div className="popup-cafe-details">
                        <Link className='feature-title' to={ data.node.data.path }>{ data.node.data.title }</Link>
                        <span><DistanceCalculator lat1={data.node.data.latitude} lon1={data.node.data.longitude} lat2={currentLocation.lat} lon2={currentLocation.lng} /></span>
                        <span>{ data.node.data.address }</span> 
                      </div>
                    </div>
                  </Popup>
                </Marker>
            )})}
          </DonutMap>
        </div>
        <CafeSidebar className="cafe-container" cafes = { data.allAirtable.edges } visible={isVisible} onSelect={handleSelectCafe} currentLocation={currentLocation} >
        </CafeSidebar>
      </Container> 
    </Layout>
  );
};

export default IndexPage;

export const cafeQuery = graphql`
  query {
    donutMarker: file(relativePath: { eq: "donut-marker.png" }) {
      childImageSharp {
        standard: fixed(height: 60) {
          src
        }
        retina: fixed(height: 120) {
          src
        }
      }
    }
    allAirtable {
      edges {
        node {
          table
          recordId
          data {
            title
            region
            slug
            path
            address
            latitude
            longitude
            photos {
              localFiles {
                childImageSharp {
                  fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
