import React from 'react';

export function distance(lat1, lon1, lat2, lon2) {
  const toRadian = angle => (Math.PI / 180) * angle;
  const distance = (a, b) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  return finalDistance;
}

const DistanceCalculator = ({lat1, lon1, lat2, lon2}) => {
  const cafe_distance = distance(lat1, lon1, lat2, lon2 );
  const distancekm = Math.round((cafe_distance + Number.EPSILON) * 100) / 100
  const distancem = Math.ceil(cafe_distance*1000/50)*50
  console.log(cafe_distance)

  return (
    cafe_distance > 1 ? distancekm + 'km' : distancem ? distancem + 'm' : ''
  );
};

export default DistanceCalculator;


