// Navbar.js

import React, { useState } from "react";
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart,  } from "@fortawesome/free-solid-svg-icons"
import DistanceCalculator from "./DistanceCalculator";



const CafeSidebar = ( { className, cafes, visible, onSelect, currentLocation, children } ) => {

    return (        
    <div className={visible ? 'sidebar' : 'sidebar hidden'}>   
    { children }
        <div className={ className }>
            
        {cafes.map((data, index) => {
            return (
                <span className="cafelist-item" key={index} onClick={()=>onSelect(index, data.node.data.latitude, data.node.data.longitude)} >
                    <figure className="item-image">
                        <Link to={ data.node.data.path }><Img fixed={data.node.data.photos.localFiles[0].childImageSharp.fixed} className="circle-crop"/></Link>
                    </figure>
                    <span className="item-content">
                        <Link className='feature-title' to={ data.node.data.path }>{ data.node.data.title }</Link>
                        <p><DistanceCalculator lat1={data.node.data.latitude} lon1={data.node.data.longitude} lat2={currentLocation.lat} lon2={currentLocation.lng} /></p>
                        <p>{data.node.data.address}</p>
                    </span>
                </span>
            )})}
        </div>
    </div>
  )
};

export default CafeSidebar;