import React, { useRef, useEffect, componentWillUnmount } from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer, ZoomControl } from 'react-leaflet';

import { useConfigureLeaflet, useMapServices, useRefEffect } from 'hooks';
import { isDomAvailable } from 'lib/util';

const DEFAULT_MAP_SERVICE = 'Mapbox';

const DonutMap = ( props ) => {
  const { children, className, defaultBaseMap = DEFAULT_MAP_SERVICE, mapEffect, center, sidebarVisible, locationHandler, ...rest } = props;
  const mapRef = useRef();

  useEffect(() => {
    if (typeof window === 'undefined') return false 

    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    let savedCenter = sessionStorage.getItem("center")
    let savedZoom = sessionStorage.getItem("zoom")
    if (savedCenter) {
      map.setView(JSON.parse(savedCenter), savedZoom)
    } else {
      map.locate({
        setView: true,
        maxZoom: 15
      });
    }

    map.on('locationfound', handleOnLocationFound);
    map.on('moveend', handleOnChange);
  }, []);

  useEffect(() => {
    mapRef.current.leafletElement.invalidateSize();
    console.log("size invalidated")
  }, [sidebarVisible])

  function handleOnLocationFound(event) {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    const latlng = event.latlng;
    const radius = event.accuracy;
    locationHandler(event.latlng);
  }

  function handleOnChange(event) {
    const { current = {} } = mapRef;
    if (current) {
      const { leafletElement: map } = current;
      sessionStorage.setItem("center", JSON.stringify(map.getCenter()))
      sessionStorage.setItem("zoom", map.getZoom())
    }
  }

  useConfigureLeaflet();

  if ( !isDomAvailable()) {
    return (
      <div className="map">
        <p className="map-loading">Loading map...</p>
      </div>
    );
  }

  return (
    <div className="map">
      <Map ref={mapRef} className="map-base" center={center} zoom={15} zoomControl={false}>
        { children }
        <TileLayer url="https://api.mapbox.com/styles/v1/jnimmo/ckd5g838h0kw51imphpv7cc46/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoiam5pbW1vIiwiYSI6ImNrOXJ6ZW55bzB6c3cza3BqNWkwZmN1c3AifQ.FHMTEvs4pDeKfqMs5bW2iQ" attribution='&copy;<a href="https://www.openstreetmap.org/">OpenStreetMap</a> &copy;<a href="https://www.mapbox.com/">Mapbox</a>' />
        <ZoomControl position="bottomright" />
      </Map>
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultBaseMap: PropTypes.string,
  mapEffect: PropTypes.func,
};

export default DonutMap;
